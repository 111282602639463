import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

class FeatureImage extends React.Component {
    render() {
        let item = this.props.item
        let alt = this.props.alt ? this.props.alt : (item.title ? item.title : '')
        alt = alt.substring(0,40)
        
        return <>
            {
                <figure {...(this.props.figureClass ? {className: this.props.figureClass} : {})}>
                    {item.feature_image
                        ?(item.localFeatureImage && item.localFeatureImage.childImageSharp
                            ?<GatsbyImage
                                image={item.localFeatureImage.childImageSharp.gatsbyImageData}
                                loading={this.props.lazyload}
                                alt={alt}
                                {...(this.props.imageClass ? {className: this.props.imageClass} : {})}
                            />
                            :<img src={item.feature_image.replace('http://', 'https://')} loading={this.props.lazyload} alt={alt} {...(this.props.imageClass ? {className: this.props.imageClass} : {})} />
                        )
                        :<img src={require('../../../static/images/placeholder-img.jpg')} loading={this.props.lazyload} alt={alt} {...(this.props.imageClass ? {className: this.props.imageClass} : {})} />
                    }
                </figure>
            }
        </>;
    }
}

export default FeatureImage