import React from 'react'
import { Link } from 'gatsby'
import SVG from 'react-inlinesvg'

import '../../styles/components/pagination.scss'

const Pagination = ({ pageContext }) => {
    const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } = pageContext

    return (
        <nav className="pagination" role="navigation">
            <div>
                {previousPagePath && (

                    <Link to={previousPagePath} rel="previous" className="pagination__btn">
                        <span>{humanPageNumber-1}</span>
                        <SVG src="/images/icons/arrow-left-thin.svg" />
                    </Link>

                )}
            </div>
            {numberOfPages > 1 && <div className="pagination-location visually-hidden">Page {humanPageNumber} of {numberOfPages}</div>}
            <div>
                {nextPagePath && (

                    <Link to={nextPagePath} rel="next" className="pagination__btn">
                        <span>{humanPageNumber+1}</span>
                        <SVG src="/images/icons/arrow-right-thin.svg" />
                    </Link>

                )}
            </div>
        </nav>
    )
}

export default Pagination
